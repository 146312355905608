
class ZIDXListingDetail implements ZIDXPage{
    slideData:string="";
    slider:ZIDXMultiPanelSlider|null=null;
    constructor() {
    }
    getPageClass(){
        return "zidxListingDetailContainer";
    }
    getMatchURL(){
        return "/listing/";
    }
    render(){
        // console.log("listing detail");
        // let j=new JetendoJSS();
        // j.generate();
        if(ZIDX.$("#listingDetailSlideshow1").length>0) {
            let tempSlideData = ZIDX.$("#listingDetailSlideshow1").attr("data-slide-data")!;
            if (this.slideData != tempSlideData || ZIDX.$("#listingDetailSlideshow1 .zidxMultiPanelSlide1").length == 0) {
                this.slideData = tempSlideData;
                // console.log("slideData", this.slideData);
                let arrSlide: string[] = JSON.parse(this.slideData);
                if (arrSlide.length > 1) {
                    var options = new ZIDXMultiPanelSliderOptions();
                    options.arrSlide = arrSlide;
                    options.selector = "#listingDetailSlideshow1";
                    options.pager = true;
                    options.pagerStyle = 'zidxMultiPanelCircles';
                    options.auto = false;
                    options.timeout = 5000;
                    options.touchSwipe = true;
                    options.nextPrevious = true;
                    options.nextButton = '<img src="'+window.ZIDXOptions.siteDomain+'/_forever/images/arrow-right.png" width="25" height="44" alt="Next Photo">';
                    options.previousButton = '<img src="'+window.ZIDXOptions.siteDomain+'/_forever/images/arrow-left.png" width="25" height="44" alt="Previous Photo">';
                    options.nextPreviousCenterClass = "#listingDetailSlideshow1 .zidxMultiPanelSlide";
                    options.slideWidthPercent = 100;
                    this.slider = new ZIDXMultiPanelSlider(options);
                }
            } else if (this.slider != null) {
                this.slider.reinit();
            }
        }

        let mapLoaded=false;
        let self=this;
        let mapContainer=document.getElementById("zidxListingDetailMap")!;
        if(mapContainer!=null && mapContainer.parentNode!=null && mapContainer.parentNode.parentNode!=null) {
            let mapAccordionItem = <HTMLDivElement>mapContainer.parentNode!.parentNode!;
            ZIDX.$(mapAccordionItem).on("click", function (this: HTMLDivElement, e: MouseEvent) {
                e.preventDefault();

                // console.log("trying to hideMap mapLoaded:"+mapLoaded);
                if (mapLoaded) {
                    mapLoaded = false;
                    zidxMap.hideListingMarker();
                    zidxMap.hideMap();
                    return;
                }
                window.zidxBase.loadMap(function () {
                    zidxMap.closeInfoWindow();
                    self.positionMap();
                    window.scrollTo(0, ZIDX.getAbsPosition(mapAccordionItem).y);
                    mapLoaded = true;
                });
            });
            ZIDX.$(window).on("clientresize resize", function(){
                let mapContainerPosition=mapContainer.getBoundingClientRect();
                if(mapContainerPosition.width==0){
                    return;
                }
                if(mapLoaded) {
                    self.positionMap();
                }
            });
        }
    }
    positionMap(){
        let mapContainer=document.getElementById("zidxListingDetailMap")!;
        let latitudeString=mapContainer.getAttribute("data-latitude");
        let longitudeString=mapContainer.getAttribute("data-longitude");
        if(latitudeString!=null && !isNaN(parseFloat(latitudeString)) && longitudeString!=null && !isNaN(parseFloat(longitudeString))){
            let latitude=parseFloat(latitudeString);
            let longitude=parseFloat(longitudeString);
            let mapContainerPosition=mapContainer.getBoundingClientRect();

            let mapElement=document.getElementById("zidxSearchMap1")!;
            console.log("showMap1");
            mapElement.style.display="block";
            mapElement.style.position="absolute";
            mapElement.style.width=(mapContainerPosition.width-16)+"px";
            mapElement.style.height=mapContainerPosition.height+"px";
            mapElement.style.left=mapContainerPosition.x+"px";
            mapElement.style.top=(ZIDX.$(window).scrollTop()+mapContainerPosition.y)+"px";
            zidxMap.setListingMarker(latitude, longitude);
            zidxMap.controlText.style.display="none";
            ZIDX.$(".zidxMapMarkerHidden").removeClass("zidxMapMarkerHidden");
            ZIDX.$(".zidxMapMarker").addClass("zidxMapMarkerHidden");
        }
    }
}